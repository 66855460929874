.red-text{
    color: #f90000;
}
.content-container h2{
    font-size: 19px;
    font-weight: 500;
    padding-bottom: 10px;
}
.content-container h3{
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 6px;
}
.content-container p{
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 18px;
}