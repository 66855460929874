.hero {
    /* Sizing */
    width: 100%;
     /* height: auto;
    max-height: 80vh;  */
    margin-top:95px;
    /* Background styles */
    background-image: url('./heroBggg.png');
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.hero-inner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;   
}
.content{
  padding: 18px;  
  margin-top: 40px;
  margin-bottom: 40px;
 
  
 
}
.content h1{
    letter-spacing: 0.04em;
color: #000000;
font-weight: 400;
font-size: 18px;
line-height: 1.2;

}
.content h2{
    /* font-weight: 400;
    font-size: 60px;
    line-height: 1.2;
    letter-spacing: 0.03em;
    color: #E01919; */
    color: #f90000;
   font-family: "Playfair Display",serif;
   font-size: 60px;
    font-weight: 400;
margin: 0px;
}
.content p{

    /* color: #000000;
   font-size: 18px;
    font-weight: 500;
    padding-bottom: 15px;
    text-transform: unset;
    line-height: 1.5; */
    color: #391515;
    font-size: 17px;
    font-weight: 400;
    max-width: 540px;
   
}
.content button{
    margin-top: 12px;
}

@media screen and (min-width: 400px) {
    .hero {     
        width: 100%;
       
        
    }
  }
  
  @media screen and (min-width: 800px) {
    .hero {     
        width: 100%;
        height: 70vh;  
        
    }
  }