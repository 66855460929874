.App-header{
    background-color: #ffff;
    margin: 0px;
}

.navbar{
    background-color: #ffff;
    
    box-shadow: 0px 2px 4px rgba(168, 160, 160, 0.25);
}
.navbar-nav .nav-item{
    margin: 5px 25px;
}
.nav-link{
font-weight: 500;
font-size: 16px;
line-height: 19px;
}
.navbar-light .navbar-nav .nav-link.active{
    color: #E01919;
    border-bottom: 1px solid #E01919;
}

.bag{
    font-size: 24px;
    margin: 0 5px;
    
}
.middle{
    margin-top: 4px;
}
.countBadge{
    position: absolute;
    display: inline-block;
    background-color: #f06a6a;
    color:#fff;
    padding: 1px 7px;
    border-radius: 100%;
    top: -9px;
    right: -10px;

}