.productCard{
    cursor: pointer;
    margin: 10px;



}


.productContent{
    text-align: left;
    padding: 12px;
}

.stockBadge{
    display: inline-block;
    padding: 2px 8px;
    border-radius: 18px;
    background-color: rgb(226, 44, 44);
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: -5px;
    right: -22px;
    padding: 12px;
    font-size: 13px;
}

.badge-green{

}