*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/bg2.svg');
 ;
  background-color:  #fbfbfb;

  /* background-color: #ffd4cab8; */
  /* background-color: #ffe1da; */
  /* background-color: #FFEEEA; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: scroll; */
}
.color-primary{
  color: #0e4b17;
  /* color: #00bfd8; */
}
.color-black{

	color: #121212 !important;
}
.text-large{
  font-size: 48px; 
}
.text-large-second{
	font-size: 18px; 
  }
.bg-w{
	background-color: #fff !important;
}
.icon-font-basic{
	font-size: 22px !important;
}
.fw600{
  font-weight: 700;
}
.text-medium{
  font-size: 38px;
}
.text-mid{
	font-size: 18px;
}
.h-250{
	height: 450px !important;
}
h1{
  color: #393939;
  font-weight: 700;
}

h2{
  color: #393939;
  font-size: 36px;
  font-weight: 700;
}

h4{
  color: #393939;
  font-size: 24px;
  font-weight: 600;
}
h5{
  color: #393939;
  font-size: 18px;
  font-weight: 600;
}
p{
  color: #626262;
  font-size: 16px;
}

.btn-primary{
	padding: 12px 28px;
	border-radius: 18px;
}
.btn-main{
	background-color: #fff;
	color: #0092a5;
	font-size: 18px;
	font-weight: 700;
}
.btn-second{
	background-color: #fff;
	color: #4c8890;
	border-color:#0092a5 ;
	font-size: 16px;
	font-weight: 600;
}
.btn-main-danger{
	background-color: #fff;
	color: #ca5139;
	font-size: 18px;
	font-weight: 700;
	border-color: #ca5139;
}
.btn-main:hover{
	background-color:#0092a5;
	color: #fff;
	transition: all ease-in 300ms;
}
.btn-second:hover{
	background-color:#0092a5;
	border-color:#0092a5 ;
	color: #fff;
	transition: all ease-in 300ms;
}

.second-container{
	padding-top: 8rem;
}

.contactCard .card-header h5{

padding-top: 5px;

}

.serviceCard{

	height: 360px;
}

.mainHead{
	line-height: 24px;
	letter-spacing: 1px;
}

.main-conatiner{


  margin-top: 92px;
}
.bg-w {
  background-color: #fff!important;
}
.aboutImg{
  height: 10vh;
}
.aboutCard{
  min-height: 320px;
}