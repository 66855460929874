.red-text{
    color: #f90000;
}
.product{

    /* padding: 18px; */
}
.product h2{
    font-size: 19px;
    font-weight: 500;
    padding-bottom: 10px;
}
.product h3{  
    font-size: 32px;
    font-weight: 500;
    padding-bottom: 6px;
}
.content-container p{
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 18px;
}
[data-quantity] {
    
    margin-bottom: 12px;
    margin-right: 18px;
    display: inline-block;

}
[data-quantity] input {
   
    font-weight: 500;
}
.Ingreadents{
    margin-bottom: 18px;
}
.chip{
    display: inline-block;
    padding: 5px 10px;
    border-color: #364636;
    background-color: #c4e9c5;
    border-radius: 16px;
    margin: 2px 5px;
}
.aboutSection{
    /* margin: 18px;
    padding: 12px; */
}
.aboutSection p{
   
line-height: 29px;
font-size: 18px;
}
.inc{
    font-size: 12px;
}
.howTomake{

    display: block;
}
.howTomake .card-header{

    background-color: #fff;
}
