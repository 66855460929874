
.main-container{
  padding: 0;
}
.btn-primary{
  font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.1em;
color: #111111;
padding: 15px 30px;
background: transparent;
border-color: #111111;
}
.card{
  display: inline-block;
  
  border-radius: 12px;
  margin: 12px;
  padding: 12px;
  
}
.cart-card{
  
  padding: 6px;
}
a{
  text-decoration: none;
  color: #111111;
}
a:hover{
  text-decoration: none;
  color: #111111;
}
.right-img{
  height: 400px;
  width: 380px;
  border-radius: 12px;
}
.text-heading{
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  color: #111111;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  margin-left: 18px;
}
.RL-section{
  padding-top: 20px;
  padding-bottom: 20px;
}
.large-container{
  background-color: #fbfbfb;
}

.content-container{

  margin: 12px;
  padding: 12px;
}


.service_card{

 background: #FFFFFF;
border: 1px solid #E0E0E0;
border-radius: 24px;
width: 170px;

}
.service_card_content{
  padding: 10px 5px;
  color: #111111;
  font-size: 16px;

}
.service_card_content p{
  font-weight: 500;
  color: #4a4848;
}

tr{
  border-color: #FFFFFF;
}
.checkoutItems{
  display: block;
}
/* .checkout h5{

  margin: 5px 16px;
} */
.checkout h5:nth-child(0){

  margin-right: 18px;
}